import React, { useState, useEffect } from "react";
import DemoClasses from "./demo.module.css";
import Layout from "../../../Components/Layout/index";
import { Helmet } from "react-helmet";
import { Services } from "../../../Services";
import { FaSearch } from "react-icons/fa";
import SkeletonLoad from "./TableLoad/load";
import { Error } from "../../../Util/Toast";
import { toast } from "react-toastify";

import { Success_Dark } from "../../../Util/Toast";
import Moment from "react-moment";
import { MdEdit } from "react-icons/md";
import moment from "moment";
import Modal from "../Modal";
import swal from "sweetalert";
import { SuccessSwal } from "../../../Util/Toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import Loader from "Components/loader/loader";
import { FaRegEye } from "react-icons/fa";
import { useForm } from "react-hook-form";
import ReactPaginate from "react-paginate";
import useStore from "../../../store";
import Select from "react-select";

function Demo() {
    const [details, setdetails] = useState({});
    const [showModal, setModal] = useState(false);
    const Navigate = useNavigate();
    const [searchString, setSearchString] = useState("");
    const [loading, setLoading] = useState(true);
    const [RequestCreatedBy, setRequestCreatedBy] = useState(null);
    const [ClaimedBy, setClaimedBy] = useState(null);

    const [Apibody, setBody] = useState({ toSkip: 0 });
    const [data, setData] = useState([]);
    const admindata = JSON.parse(localStorage.getItem("admindata"));
    const [filterLoad, setFilterLoad] = useState(false);
    const [Sales, setSales] = useState([]);
    const [searchparams, setSearchParams] = useSearchParams();
    const [allAdmin, setAllAdmin] = useState([]);
    const [forcepage, setforcepage] = useState(0);
    const [pageCount, setpageCount] = useState(1);
    const [showPaginate, setShowPagiate] = useState(true);
    // const RecordCount = useStore((item) => item.NoOfRecords);
    const RecordCount = 20;

    const [currentPage, setCurrentPage] = useState(1);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm();
    const getAllCategories = (pageNumber, body) => {
        Services.Demo_Request(
            "POST",
            JSON.stringify({ toSkip: pageNumber, ...body }),
            token
        )
            .then((response) => {
                console.log(response);
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response.DemoRequests);
                    if (
                        response.DemoRequests.length == 0 ||
                        response.Count <= RecordCount
                    ) {
                        setShowPagiate(false);
                    } else {
                        if (!showPaginate) {
                            setShowPagiate(true);
                        }
                        setpageCount(
                            Math.ceil(
                                Number(response?.RequestsCount) /
                                    Number(RecordCount)
                            )
                        );
                    }
                } else if (response.Status === 0) {
                    Error(response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };

    useEffect(() => {
        getManagers();
        getAllAdmin();
        let prevData = localStorage.getItem("demo_filter");
        let page = searchparams.get("page");
        setforcepage(Number(page) - 1);
        if (prevData != "undefined") {
            prevData = JSON.parse(prevData);
            if (prevData?.FromDate) {
                setValue("FromDate", prevData?.FromDate);
            }
            if (prevData?.ToDate) {
                setValue("ToDate", prevData?.ToDate);
            }
            if (prevData?.MobileNumber) {
                setValue("MobileNumber", prevData?.MobileNumber);
            }

            if (prevData?.RequestCreatedBy) {
                setAllAdmin([
                    { value: 0, label: "Parent" },
                    {
                        value: prevData?.RequestCreatedBy,
                        label: prevData?.RequestCreatedByLabel,
                    },
                ]);

                setRequestCreatedBy({
                    value: prevData?.RequestCreatedBy,
                    label: prevData?.RequestCreatedByLabel,
                });
            }
            if (prevData?.ClaimedBy) {
                setSales([
                    {
                        value: prevData?.ClaimedBy,
                        label: prevData?.ClaimedByLabel,
                    },
                ]);

                setClaimedBy({
                    value: prevData?.ClaimedBy,
                    label: prevData?.ClaimedByLabel,
                });
            }
            setBody(prevData);
            getAllCategories((Number(page) - 1) * RecordCount, prevData);
        } else {
            getAllCategories((Number(page) - 1) * RecordCount);
        }
    }, []);
    const claimHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();

        let EnrollCourseID = e.currentTarget.getAttribute("EnrollCourseID");
        let coursename = e.currentTarget.getAttribute("coursename");
        let body = {
            IsClaimed: 1,
            ClaimedBy: admindata.AdminUserID,
            CourseName: coursename,
        };
        swal("Are you sure about Claiming this User?", {
            icon: "warning",
            // className: detailsClass["logout-popup"],
            dangerMode: true,
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Confirm",
                    value: "Logout",
                    // className: enrolledClasses["cancel"],
                },
                defeat: false,
            },
        }).then((value) => {
            switch (value) {
                case "Logout":
                    setFilterLoad(true);
                    Services.Claim_Enroll_Course_demo(
                        "PUT",
                        JSON.stringify(body),
                        token,
                        EnrollCourseID
                    )
                        .then((res) => {
                            if (res.Status === 1) {
                                SuccessSwal(
                                    "Success",
                                    "Student profile claimed successfully"
                                );
                                let page = searchparams.get("page");

                                getAllCategories(
                                    (Number(page) - 1) * RecordCount,
                                    Apibody
                                );
                                setFilterLoad(false);
                            } else {
                                setFilterLoad(false);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            setLoading(false);
                            Error("something went Wrong Please try again");
                            setFilterLoad(false);
                            // setFilterLoad(false)
                        });
                    break;

                // default:
                //     swal("Got away safely!");
            }
        });
    };
    const resetClaimStatus = (EnrollCourseID) => {
        let body = {
            IsClaimed: 0,
            ClaimedBy: 0,
        };
        swal("Are you sure you want to un-claim the User?", {
            icon: "warning",
            // className: detailsClass["logout-popup"],
            dangerMode: true,
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Confirm",
                    value: "Logout",
                    // className: enrolledClasses["cancel"],
                },
                defeat: false,
            },
        }).then((value) => {
            switch (value) {
                case "Logout":
                    setFilterLoad(true);
                    Services.Claim_Enroll_Course_demo(
                        "PUT",
                        JSON.stringify(body),
                        token,
                        EnrollCourseID
                    )
                        .then((res) => {
                            if (res.Status === 1) {
                                SuccessSwal(
                                    "Success",
                                    "Student profile claimed successfully"
                                );
                                getAllCategories();
                                setFilterLoad(false);
                            } else {
                                setFilterLoad(false);
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            setLoading(false);
                            Error("something went Wrong Please try again");
                            setFilterLoad(false);
                            // setFilterLoad(false)
                        });
                    break;

                // default:
                //     swal("Got away safely!");
            }
        });
    };

    // useEffect(() => {
    //     const debouncedSearch = setTimeout(async () => {
    //         if (searchString) {
    //             setLoading(true);

    //             try {
    //                 const data = await Services.Demo_Request_Search(
    //                     "GET",
    //                     null,
    //                     token,
    //                     searchString
    //                 );
    //                 setData(data.DemoRequests);
    //             } catch (error) {
    //                 console.error("Error fetching options:", error);
    //             } finally {
    //                 setLoading(false);
    //             }
    //         } else {
    //             getAllCategories();
    //         }
    //     }, 300);

    //     return () => clearTimeout(debouncedSearch);
    //     // eslint-disable-next-line
    // }, [searchString]);
    const ClaimOnClickHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const onSubmit = (data) => {
        let body;

        if (data.MobileNumber) {
            body = {
                MobileNumber: data.MobileNumber,
                whether_Mobile_filter: 1,
            };
        }
        if (data.FromDate || data.ToDate) {
            if (data.FromDate) {
                if (!data.ToDate) {
                    body = {
                        ...body,
                        FromDate: data.FromDate,
                        ToDate: data?.FromDate,
                    };
                } else {
                    body = {
                        ...body,
                        FromDate: data.FromDate,
                        ToDate: data?.ToDate,
                    };
                }
            }
            if (data.ToDate) {
                if (!data.FromDate) {
                    body = {
                        ...body,
                        FromDate: data.ToDate,
                        ToDate: data?.ToDate,
                    };
                } else {
                    body = {
                        ...body,
                        FromDate: data.FromDate,
                        ToDate: data?.ToDate,
                    };
                }
            }
        }
        if (data?.AdminUserID) {
            body = { ...body, AdminUserID: data?.AdminUserID };
        }
        if (RequestCreatedBy) {
            body = {
                ...body,
                RequestCreatedBy: RequestCreatedBy.value,
                RequestCreatedByLabel: RequestCreatedBy.label,
            };
        }
        if (ClaimedBy) {
            body = {
                ...body,
                ClaimedBy: ClaimedBy.value,
                ClaimedByLabel: ClaimedBy.label,
            };
        }

        if (data?.CourseID) {
            body = { ...body, CourseID: data?.CourseID };
        }
        setforcepage(0);
        setLoading(true);
        localStorage.setItem("demo_filter", JSON.stringify(body));

        setBody(body);
        setforcepage(0);
        getAllCategories(0, body);
    };

    const handlePageChange = (selectedObject) => {
        console.log(selectedObject);
        // setcurrentPage(selectedObject.selected);
        // handleFetch();
        setSearchParams({ page: selectedObject.selected + 1 });
        window.scrollTo({ top: 10, behavior: "instant" });
        setforcepage(selectedObject.selected);

        getAllCategories(selectedObject.selected * RecordCount, Apibody);
        setLoading(true);
    };
    const getManagers = () => {
        Services.list_salesmanger("GET", null, token)
            .then((response) => {
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setSales(
                        response?.AdminUsers?.map((item) => ({
                            label: `${item?.FirstName} ${item?.LastName}`,
                            value: item?.AdminUserID,
                        }))
                    );
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const getAllAdmin = () => {
        Services.listAllAdmin("POST", null, token)
            .then((response) => {
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setAllAdmin([
                        { value: 0, label: "Parent" },
                        ...response?.AdminUsers?.map((item) => ({
                            label: `${item?.FirstName} ${item?.LastName}`,
                            value: item?.AdminUserID,
                        })),
                    ]);
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const clearHandler = () => {
        localStorage.removeItem("demo_class_filter");
        reset();
        setClaimedBy(null);
        setRequestCreatedBy(null);
        setBody(null);
        setforcepage(0);
        getAllCategories(0);
    };
    return (
        <Layout Active={"Demo Requests"}>
            {showModal && (
                <Modal
                    getDetails={details}
                    modal={showModal}
                    setModal={setModal}
                    getAllCategories={getAllCategories}
                />
            )}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Demo Requests</title>
            </Helmet>
            {filterLoad && <Loader />}

            <div className={DemoClasses["Container"]}>
                {/*   <div className={DemoClasses["control-btns"]}>
                    <div>
                        <FaSearch className={DemoClasses["search-icon"]} />
                        <input
                            onChange={(e) => setSearchString(e.target.value)}
                            value={searchString}
                            type="number"
                            placeholder="Search By Mobile Number"
                        />
                    </div>
                </div> */}

                <div className={DemoClasses["Tutor"]}>
                    <h3>
                        Demo Requests
                        <button onClick={() => Navigate("/add-demo-student")}>
                            {" "}
                            + Add Demo Request
                        </button>
                    </h3>
                    {/* <h2>All</h2>{" "} */}
                    <form
                        className={DemoClasses["Filter"]}
                        onSubmit={handleSubmit(onSubmit)}>
                        <div className={DemoClasses["form_control"]}>
                            <label htmlFor="">Mobile Number</label>
                            <div>
                                <FaSearch
                                    className={DemoClasses["search-icon"]}
                                />
                                <input
                                    {...register("MobileNumber", {
                                        required: false,
                                    })}
                                    type="number"
                                    placeholder="Mobile Number"
                                />
                            </div>
                        </div>
                        <div className={DemoClasses["form_control"]}>
                            <label htmlFor="">From Date</label>
                            <input
                                style={{ textTransform: "uppercase" }}
                                {...register("FromDate", {
                                    required: false,
                                })}
                                type="date"
                            />
                        </div>

                        <div className={DemoClasses["form_control"]}>
                            <label htmlFor="">To Date</label>
                            <input
                                style={{ textTransform: "uppercase" }}
                                {...register("ToDate", {
                                    required: false,
                                })}
                                type="date"
                            />
                        </div>
                        <div className={DemoClasses["form-control"]}>
                            <label htmlFor="Tutor">Requested By</label>
                            <Select
                                options={allAdmin}
                                onChange={(value) => setRequestCreatedBy(value)}
                                value={RequestCreatedBy}
                                placeholder="Search"
                                noOptionsMessage={() => "No Data Found"}
                                classNames={DemoClasses["select"]}
                                styles={{
                                    // Set the width to 100%
                                    control: (provided) => ({
                                        ...provided,
                                        width: "14rem",
                                        borderRadius: "10px",
                                    }),
                                }}
                                isClearable
                                components={{
                                    ClearIndicator: () => (
                                        <button
                                            onClick={() =>
                                                setRequestCreatedBy(null)
                                            }>
                                            Clear
                                        </button>
                                    ),
                                }}
                            />
                        </div>
                        <div className={DemoClasses["form-control"]}>
                            <label htmlFor="Tutor">Lead Owner</label>
                            <Select
                                options={Sales}
                                onChange={(value) => setClaimedBy(value)}
                                value={ClaimedBy}
                                placeholder="Search"
                                noOptionsMessage={() => "No Data Found"}
                                classNames={DemoClasses["select"]}
                                styles={{
                                    // Set the width to 100%
                                    control: (provided) => ({
                                        ...provided,
                                        width: "14rem",
                                        borderRadius: "10px",
                                    }),
                                }}
                                isClearable
                                components={{
                                    ClearIndicator: () => (
                                        <button
                                            onClick={() => setClaimedBy(null)}>
                                            Clear
                                        </button>
                                    ),
                                }}
                            />
                        </div>
                        <button>Submit</button>
                        <button
                            style={{
                                border: "1px solid #0077b6",
                                backgroundColor: "white",
                                color: "#0077b6",
                            }}
                            onClick={clearHandler}>
                            Clear
                        </button>
                    </form>
                    {loading ? (
                        <SkeletonLoad count={8} />
                    ) : data.length > 0 ? (
                        <div className={DemoClasses["Table"]}>
                            <table>
                                <thead>
                                    <tr>
                                        {admindata.Type === 5 && <th></th>}
                                        <th>Parent</th>
                                        <th>Student</th>
                                        <th>Course</th>

                                        <th>Requested Date</th>
                                        <th>Requested By</th>
                                        <th>Claimed Owner</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item) => (
                                        <tr>
                                            {admindata.Type === 5 && (
                                                <td style={{ width: "2rem" }}>
                                                    <input
                                                        onClick={
                                                            ClaimOnClickHandler
                                                        }
                                                        onChange={claimHandler}
                                                        type="checkbox"
                                                        disabled={
                                                            item.IsClaimed === 1
                                                        }
                                                        coursename={
                                                            item.CourseName
                                                        }
                                                        defaultChecked={
                                                            item.IsClaimed === 1
                                                        }
                                                        checked={
                                                            item.IsClaimed === 1
                                                        }
                                                        EnrollCourseID={
                                                            item.DemoRequestID
                                                        }
                                                    />
                                                </td>
                                            )}
                                            <td
                                                style={{
                                                    width: "10rem",
                                                    wordBreak: "break-word",
                                                }}>
                                                {" "}
                                                {item.ParentName}
                                                <br />({item.CountryCode}
                                                {item.MobileNumber})
                                            </td>
                                            <td>{item.StudentName}</td>
                                            <td>{item.CourseName}</td>

                                            <td>
                                                <Moment
                                                    subtract={{
                                                        hours: 5,
                                                        minutes: 30,
                                                    }}
                                                    format="DD-MM-YYYY">
                                                    {new Date(item.CreatedAt)}
                                                </Moment>
                                            </td>
                                            <td>
                                                {item?.RequestCreatedBy_Type ==
                                                4
                                                    ? "Parent"
                                                    : item?.RequestCreatedByName}
                                            </td>
                                            <td>
                                                {item?.ClaimedByName
                                                    ? item?.ClaimedByName
                                                    : ""}
                                            </td>
                                            <td>
                                                <div
                                                    className={
                                                        DemoClasses[
                                                            "Action-btns"
                                                        ]
                                                    }>
                                                    {(admindata.Type == 1 ||
                                                        admindata?.AdminUserID ==
                                                            142) && (
                                                        <button
                                                            onClick={() =>
                                                                resetClaimStatus(
                                                                    item?.DemoRequestID
                                                                )
                                                            }
                                                            disabled={
                                                                item?.ClaimedByName ==
                                                                null
                                                            }>
                                                            Reset Claim
                                                        </button>
                                                    )}
                                                    <button
                                                        className={
                                                            DemoClasses[
                                                                "edit-btn"
                                                            ]
                                                        }
                                                        onClick={() => {
                                                            localStorage.setItem(
                                                                "demo",
                                                                JSON.stringify({
                                                                    ...item,
                                                                    DOB: moment(
                                                                        item?.DOB
                                                                    ).format(
                                                                        "YYYY-MM-DD"
                                                                    ),
                                                                })
                                                            );
                                                            Navigate(
                                                                `/add-demo-student?id=${item.DemoRequestID}`
                                                            );
                                                        }}>
                                                        <MdEdit />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {showPaginate && (
                                <div
                                    style={{
                                        justifySelf: "right",
                                    }}>
                                    <ReactPaginate
                                        pageCount={pageCount}
                                        // pageRange={2}
                                        forcePage={forcepage}
                                        marginPagesDisplayed={2}
                                        onPageChange={handlePageChange}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        containerClassName={"pagination"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            {" "}
                            <p>No Record Found</p>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Demo;
